"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommaLengthValidator = void 0;
const ng = window.angular;
function CommaLengthValidator() {
    const defaultRegex = new RegExp('\s?,', 'g');
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            commaLength: '=',
            commaLengthRegex: '=?',
            commaLengthTransform: '&?',
        },
        //@ts-ignore
        link: ($scope, $element, attr, $ngModel) => {
            $ngModel.$validators.commaLength = (modelValue, viewValue) => {
                if (viewValue != undefined) {
                    if ($scope.commaLengthTransform != undefined) {
                        return $scope.commaLengthTransform({ value: viewValue }).length >= $scope.commaLength;
                    }
                    else if ($scope.commaLengthRegex != undefined) {
                        return viewValue.split($scope.commaLengthRegex).length >= $scope.commaLength;
                    }
                    else {
                        return viewValue.split(defaultRegex).length >= $scope.commaLength;
                    }
                }
                return true;
            };
        },
    };
}
exports.CommaLengthValidator = CommaLengthValidator;
CommaLengthValidator.$inject = [];
